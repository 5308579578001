import { createContext } from "react";

export enum AuthenticateStatus {
  NOT_AUTHENTICATED,
  PENDING_2FA,
  AUTHENTICATED,
}

export interface InitialValues {
  isFetching: boolean;
  authenticateStatus: AuthenticateStatus;
  user: string | undefined;
}

interface AuthenticationContextProps extends InitialValues {
  resetPassword: (token: string, plainPassword: string) => Promise<void>;
  checkPasswordResetToken: (token: string) => Promise<string>;
  requestPasswordReset: (emailAddress: string) => Promise<void>;
  loginUser: (emailAddress: string, password: string) => Promise<"challenge" | "configure" | "completed">;
  validate2Fa: (value: string) => Promise<void>;
  loginImpersonatingUser: (emailAddress: string) => Promise<void>;
  logoutUser: () => Promise<void>;
}

export const AuthenticationContext = createContext<AuthenticationContextProps>({
  authenticateStatus: AuthenticateStatus.NOT_AUTHENTICATED,
  isFetching: false,
  user: undefined,
  loginUser: () => Promise.reject(),
  loginImpersonatingUser: () => Promise.reject(),
  logoutUser: () => Promise.reject(),
  validate2Fa: () => Promise.reject(),
  requestPasswordReset: () => Promise.reject(),
  checkPasswordResetToken: () => Promise.reject(),
  resetPassword: () => Promise.reject(),
});
