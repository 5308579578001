import { Avatar, List, Modal, Skeleton, Space, Typography } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { gql, useMutation, useQuery } from "urql";

import Comment from "@/components/Comment";
import MyAvatar from "@/components/MyAvatar";
import formatAddress from "@/functions/format-address";

import CommentEditor from "./CommentEditor";
import RelatedProducstTable from "./RelatedProductsTable";

interface IssueModalProps {
  onClose: () => void;
  issueId: string;
}

export default function IssueModal({ onClose, issueId }: IssueModalProps) {
  const [{ fetching: isPostingCommentAsync }, postCommentAsync] = useMutation(PostCommentMutation);
  const [{ data }] = useQuery({ query: IssueQuery, variables: { issueId } });
  const issue = data?.issue ?? undefined;

  const commentsSortedPostedOn = React.useMemo(() => {
    if (undefined === issue) return [];

    return [...issue.comments].sort((a, b) => {
      const aDayJs = dayjs(a.postedOn);
      const bDayJs = dayjs(b.postedOn);

      if (aDayJs.isAfter(bDayJs)) return 1;
      if (bDayJs.isAfter(aDayJs)) return -1;

      return 0;
    });
  }, [issue]);

  const handleOnSubmitComment = async (value: string) => {
    await postCommentAsync({ id: issueId, comment: value });
  };

  return (
    <Modal
      styles={{
        body: {
          overflowY: "auto",
          maxHeight: window.innerHeight - 168,
          scrollbarWidth: "none",
        },
      }}
      centered
      footer={null}
      onCancel={onClose}
      width="35%"
      open
    >
      {undefined === issue ? (
        <Skeleton />
      ) : (
        <Space direction="vertical" style={{ width: "100%" }} size={16}>
          <div>
            <div style={{ fontWeight: "bold" }}>{`(${issue.relation.afasCode}) ${issue.relation.name}`}</div>
            {issue.relation.name !== issue.location.name && <div>{issue.location.name}</div>}
            <div>{formatAddress(issue.location.address)}</div>
          </div>
          {/* eslint-disable-next-line prettier/prettier */}
          {issue.relatedProducts.length > 0 && <RelatedProducstTable products={issue.relatedProducts} />}
          <List
            header={
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {issue.subject}
                </Typography.Title>
                <span>{issue.comments.length === 1 ? "1 opmerking" : `${issue.comments.length} opmerkingen`}</span>
              </div>
            }
            footer={
              <Comment
                avatar={<Avatar size={18} />}
                content={<CommentEditor onSubmit={handleOnSubmitComment} submitting={isPostingCommentAsync} />}
              />
            }
            itemLayout="horizontal"
            dataSource={commentsSortedPostedOn}
            renderItem={(comment: Record<string, any>) => {
              return (
                <Comment
                  author={comment.postedBy.username}
                  avatar={<MyAvatar username={comment.postedBy.username} />}
                  content={comment.text}
                  datetime={dayjs(comment.postedOn).format("DD/MM/YYYY")}
                />
              );
            }}
          />
        </Space>
      )}
    </Modal>
  );
}

const IssueQuery = gql`
  query ($issueId: ID!) {
    issue(id: $issueId) {
      id
      subject
      relation {
        id
        afasCode
        name
      }
      location {
        id
        name
        address {
          street
          city
          postalCode
          country
        }
      }
      comments {
        id
        postedBy {
          id
          username
          email
        }
        postedOn
        text
      }
      relatedProducts {
        id
        productType {
          id
          description
        }
        locationDescription
        serialCode
        replacementDate
      }
    }
  }
`;

const PostCommentMutation = gql`
  mutation ($id: ID!, $comment: String!) {
    postComment(input: { id: $id, comment: $comment }) {
      issue {
        id
        status
        comments {
          id
          postedOn
          postedBy {
            id
            username
            email
          }
          text
        }
        assignedTo {
          id
        }
      }
    }
  }
`;
