import { createBrowserRouter, Navigate, RouterProvider, useSearchParams } from "react-router-dom";

import { AuthenticateStatus, useAuthentication } from "@/authentication";
import AppLayout from "@/components/Layout";
import * as Authentication from "@/routes/Authentication";

import { routes } from "./routes";

const router = createBrowserRouter([
  {
    path: "authenticate",
    element: <Authentication.Layout />,
    children: [
      {
        path: "login",
        element: <Authentication.Login />,
      },
      {
        path: "2fa-challenge",
        element: <Authentication.ChallengeTwoFactor />,
      },
      {
        path: "2fa-configure",
        element: <Authentication.ConfigureTwoFactor />,
      },
      {
        path: "reset-password",
        element: <Authentication.ResetPassword />,
      },
      {
        index: true,
        element: <Navigate to="/authenticate/login" />,
      },
    ],
  },
  {
    path: "/reset-password",
    element: <ResetPasswordRedirect />,
  },
  {
    path: "/",
    element: <AuthenticatedRootLayout />,
    children: routes.map(item => ({
      path: item.path,
      element: item.element,
    })),
  },
]);

function ResetPasswordRedirect() {
  const [params] = useSearchParams();

  return <Navigate to={`/authenticate/reset-password?${params.toString()}`} />;
}

function AuthenticatedRootLayout() {
  const { authenticateStatus } = useAuthentication();

  return authenticateStatus === AuthenticateStatus.AUTHENTICATED ? <AppLayout /> : <Navigate to="/authenticate/login" />;
}

export function DefibrionApp() {
  return <RouterProvider router={router} />;
}
