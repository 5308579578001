import "./index.css";

import * as Sentry from "@sentry/react";
import { App as AntApp } from "antd";
import { createRoot } from "react-dom/client";

import { AuthenticationContextProvider } from "@/authentication";
import ConfigProvider from "@/components/ConfigProvider";
import { ENVIRONMENT, SENTRY_DSN } from "@/constants";
import { ClientProvider } from "@/urql";

import { DefibrionApp } from "./App";

Sentry.init({
  enabled: ENVIRONMENT === "prod",
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.0,
});

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<span>Woops! Something went wrong</span>} showDialog>
      <AntApp>
        <ClientProvider>
          <AuthenticationContextProvider>
            <ConfigProvider>
              <DefibrionApp />
            </ConfigProvider>
          </AuthenticationContextProvider>
        </ClientProvider>
      </AntApp>
    </Sentry.ErrorBoundary>
  );
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(<App />);
