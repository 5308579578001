import { createContext, useContext, useMemo, useState } from "react";
import { Client, Provider as UrqlProvider } from "urql";

import { configureClient } from "./configure-client";

let clientHandle: Client | undefined;
export function getClientHandle() {
  if (clientHandle === undefined) {
    throw new Error("Client not initiated");
  }

  return clientHandle;
}

function createClientHandle() {
  clientHandle = configureClient();

  return clientHandle;
}

const ClientContext = createContext({
  resetClient: () => {},
});

interface ClientProviderProps {
  children: React.ReactNode;
}

export function ClientProvider({ children }: ClientProviderProps) {
  const [client, setClient] = useState(() => createClientHandle());
  const value = useMemo(() => ({ resetClient: () => setClient(createClientHandle()) }), []);

  return (
    <ClientContext.Provider value={value}>
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </ClientContext.Provider>
  );
}

export function useClientContext() {
  return useContext(ClientContext);
}
