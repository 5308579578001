import { BACKEND_URL } from "@/constants";

export async function authenticate(username: string, password: string) {
  const response = await fetch(BACKEND_URL + "/users/authenticate", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
    credentials: "include",
  });

  const content = (await response.json()) as AuthenticatedSuccessResponse | AuthenticatedFailedResponse | TwoFactorPendingResponse;

  if (typeof (content as AuthenticatedFailedResponse).code === "number") {
    throw new Error((content as AuthenticatedFailedResponse).message);
  }

  if (typeof (content as TwoFactorPendingResponse).two_factor === "string") {
    return { user: undefined, ...(content as TwoFactorPendingResponse) };
  }

  return {
    login: "success" as const,
    two_factor: "skipped" as const,
    user: (content as AuthenticatedSuccessResponse).user,
  };
}

export async function validate2FaCode(authCode: string) {
  const response = await fetch(BACKEND_URL + "/users/2fa-check", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ data: { authCode } }),
  });

  const content = await response.json();

  if (response.ok) {
    return content as AuthenticatedSuccessResponse;
  } else {
    throw new Error((content as AuthenticatedFailedResponse).message);
  }
}

export async function logout() {
  try {
    await fetch(BACKEND_URL + "/users/logout", {
      credentials: "include",
    });
  } catch {
    // ..
  }
}

export interface AuthenticatedSuccessResponse {
  user: {
    id: number;
    email: string;
    locale: string;
    groups: string[];
  };
}

export interface AuthenticatedFailedResponse {
  code: 401;
  message: string;
}

export interface TwoFactorPendingResponse {
  login: "success";
  two_factor: "challenge" | "configure";
}

export interface TwoFactorFailedResponse {
  login: "2fa_failed";
  two_factor: "challenge";
}

export interface TwoFactorRequiredResponse {
  login: "access_denied";
  two_factor: "challenge";
}

export interface TwoFactorCompletedResponse {
  login: "success";
  two_factor: "completed";
}
