import { Alert, Button, Input, InputRef, Space, Spin, Typography } from "antd";
import { useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router";

import { AuthenticateStatus, useAuthentication } from "@/authentication";

import LogoImagePng from "../../../assets/logo-straight.png";

export function ChallengeTwoFactor() {
  const navigate = useNavigate();

  const { authenticateStatus, isFetching, validate2Fa } = useAuthentication();
  const [isValid, setIsValid] = useState(true);

  if (authenticateStatus !== AuthenticateStatus.PENDING_2FA) {
    return <Navigate to="/" />;
  }

  const handleValidate = async (value: string) => {
    try {
      await validate2Fa(value);
    } catch {
      setIsValid(false);
    }
  };

  return (
    <Space align="center" direction="vertical" style={{ width: "100%" }}>
      <img src={LogoImagePng} style={{ display: "block", margin: "auto", marginBottom: 22, width: 200 }} />
      <Typography.Text>Voer de 6-cijferige code die in uw 2FA applicatie verschijnt.</Typography.Text>

      <>
        <TotpInput disabled={isFetching} onChange={handleValidate} onInput={() => setIsValid(true)} />
        {isFetching ? (
          <Spin />
        ) : !isValid ? (
          <Alert type="error" message="Code is niet correct. Probeer het nogmaals." showIcon />
        ) : (
          <span />
        )}
      </>

      <Button onClick={() => navigate(-1)}>Ga terug</Button>

      <Typography.Text type="secondary">
        Heeft u problemen met de instellingen van 2FA? Stuur ons een e-mail op
        <a href="mailto:service@defibrion.nl"> service@defibrion.nl</a>
      </Typography.Text>
    </Space>
  );
}

interface TotpInputProps {
  disabled: boolean;
  onChange: (value: string) => void;
  onInput: () => void;
}

function TotpInput({ disabled, onChange, onInput }: TotpInputProps) {
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<Array<InputRef | null>>([]);

  const handleChange = (index: number, value: string) => {
    if (value.length > 1) return;

    const nextValue = digits.with(index, value);
    setDigits(nextValue);

    // Move to next input if value is entered
    if (value !== "" && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    // Validate when all digits are filled
    if (nextValue.every(digit => digit !== "")) {
      const code = nextValue.join("");
      onChange(code);
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent) => {
    if (event.key === "Backspace" && digits[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text").slice(0, 6);

    if (/^\d+$/.test(pastedData)) {
      const newDigits = [...digits];
      pastedData.split("").forEach((digit, index) => {
        if (index < 6) newDigits[index] = digit;
      });
      setDigits(newDigits);

      // Focus last filled input
      const lastIndex = Math.min(pastedData.length - 1, 5);
      inputRefs.current[lastIndex]?.focus();
    }
  };

  return (
    <Space direction="horizontal" onPaste={handlePaste}>
      {digits.map((digit, index) => (
        <Input
          key={index}
          ref={el => (inputRefs.current[index] = el)}
          disabled={disabled}
          type="text"
          inputMode="numeric"
          pattern="\d*"
          maxLength={1}
          value={digit}
          onChange={e => handleChange(index, e.target.value.replace(/\D/g, ""))}
          onInput={onInput}
          onKeyDown={e => handleKeyDown(index, e)}
          style={{
            textAlign: "center",
            height: 50,
            width: 50,
            verticalAlign: "center",
          }}
        />
      ))}
    </Space>
  );
}
