import { retryExchange, RetryExchangeOptions } from "@urql/exchange-retry";
import { cacheExchange, Client, fetchExchange } from "urql";

import { isMimickingUser, resetUserSession } from "@/authentication/token-storage";
import { BACKEND_URL } from "@/constants";

const retryOptions: RetryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: error => {
    const isCredError = error.toString().includes("401") || error.toString().includes("403") || error.toString().includes("Unauthorized");

    if (isCredError) {
      resetUserSession();
    }

    // only re-attempt if the error was because the token is expired.
    return false;
  },
};

export function configureClient() {
  return new Client({
    url: BACKEND_URL + "/graphql/customers",
    exchanges: [cacheExchange, retryExchange(retryOptions), fetchExchange],
    fetchOptions: () => {
      const mimickingUser = isMimickingUser();

      if (undefined === mimickingUser) {
        return { credentials: "include" };
      }

      return {
        credentials: "include",
        headers: {
          "X-Switch-User": mimickingUser,
        },
      };
    },
  });
}
