import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Space, Typography } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useAuthentication } from "@/authentication";
import useQueryParams from "@/hooks/use-query-params";

import LogoImagePng from "../../../assets/logo-straight.png";

export function Login() {
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const { isFetching, loginUser, requestPasswordReset, user } = useAuthentication();

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [mode, setMode] = React.useState<"login" | "request-password-reset">("login");
  const [passwordResetWasRequested, setPasswordResetWasRequested] = React.useState(false);
  const [passwordWasReset, setPasswordWasReset] = React.useState(Boolean(queryParams.get("password-reset")));

  const handleOnSubmit = async (values: Record<string, string>) => {
    setErrorMessage(undefined);
    setPasswordResetWasRequested(false);

    try {
      if (mode === "login") {
        const response = await loginUser(values.emailAddress, values.password);
        navigate(response === "completed" ? "/" : response === "configure" ? "/authenticate/2fa-configure" : "/authenticate/2fa-challenge");
      }

      if (mode === "request-password-reset") {
        await requestPasswordReset(values.emailAddress);

        setPasswordResetWasRequested(true);
        setMode("login");
      }
    } catch (error) {
      setErrorMessage((error as Error).message);
      setPasswordWasReset(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <img src={LogoImagePng} style={{ display: "block", margin: "auto", marginBottom: 22, width: 200 }} />
      {mode === "request-password-reset" && (
        <Typography.Text>
          Vul je e-mail adres in en je ontvangt een bericht met instructies om je wachtwoord opnieuw in te stellen.
        </Typography.Text>
      )}

      {(undefined !== errorMessage || passwordResetWasRequested || passwordWasReset) && (
        <div style={{ marginBottom: 12 }}>
          {errorMessage && <Alert message={errorMessage} type="error" />}
          {passwordResetWasRequested && <Alert message="Controleer je e-mail voor instructies." type="info" />}
          {passwordWasReset && <Alert message="Wachtwoord is aangepast. U kunt opnieuw inloggen." type="info" />}
        </div>
      )}

      <Form onFinish={handleOnSubmit} layout="vertical">
        <Form.Item name="emailAddress" initialValue={user}>
          <Input prefix={<UserOutlined />} placeholder="E-mail adres" required />
        </Form.Item>
        {mode === "login" && (
          <Form.Item name="password">
            <Input.Password prefix={<LockOutlined />} placeholder="Wachtwoord" required />
          </Form.Item>
        )}
        <Button block loading={isFetching} htmlType="submit" type="primary">
          {mode === "login" ? "Inloggen" : "Nieuw wachtwoord aanvragen"}
        </Button>
        <Button
          onClick={() => {
            setErrorMessage(undefined);
            setMode(mode === "login" ? "request-password-reset" : "login");
          }}
          type="text"
          style={{ marginTop: 4, padding: 0 }}
        >
          {mode === "login" ? "Wachtwoord vergeten?" : "Terug"}
        </Button>
      </Form>
    </Space>
  );
}
