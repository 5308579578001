import { Button, Space, Typography } from "antd";
import { Navigate, useNavigate } from "react-router";

import { AuthenticateStatus, useAuthentication } from "@/authentication";
import { BACKEND_URL } from "@/constants";

import LogoImagePng from "../../../assets/logo-straight.png";

export function ConfigureTwoFactor() {
  const navigate = useNavigate();
  const { authenticateStatus } = useAuthentication();

  if (authenticateStatus !== AuthenticateStatus.PENDING_2FA) {
    return <Navigate to="/" />;
  }

  const handleContinue = () => {
    navigate("/authenticate/2fa-challenge");
  };

  return (
    <Space align="center" direction="vertical" style={{ width: "100%" }}>
      <img src={LogoImagePng} style={{ display: "block", margin: "auto", marginBottom: 22, width: 200 }} />
      <Typography.Text>Configureer tweefactor-authenticatie om door te gaan.</Typography.Text>

      <Typography.Text>
        Scan onderstaande QR-code met je favoriete 2FA app, zoals bijvoorbeeld: Google Authenticator of{" "}
        <a href="https://www.microsoft.com/nl-nl/security/mobile-authenticator-app" target="_blank" rel="noreferrer">
          Microsoft Authenticator.
        </a>
      </Typography.Text>

      <img src={TWOFACTOR_QR_CODE} />

      <Button onClick={handleContinue}>Doorgaan</Button>
    </Space>
  );
}

const TWOFACTOR_QR_CODE = BACKEND_URL + "/users/2fa-qr-code";
