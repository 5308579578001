import { gql } from "urql";

export default gql`
  query ($relationId: ID, $locationId: ID, $condition: [ProductCondition!]) {
    productsSummary(relationId: $relationId, locationId: $locationId, condition: $condition) {
      productType {
        id
        code
        description
        category {
          id
          name
          parent {
            id
            name
          }
        }
      }
    }
  }
`;
