import { Button, Carousel, Descriptions, Modal, Skeleton, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { gql, useQuery } from "urql";

import formatProductDescription from "@/functions/format-product-description";

import ProductCondition from "./ProductCondition";

interface ShowProductModalProps {
  onClose: () => void;
  productId: string;
}

export default function ShowProductModal({ onClose, productId }: ShowProductModalProps) {
  const [{ data }] = useQuery({ query: ServicedProductQuery, variables: { productId } });
  const product = data?.product;

  return (
    <Modal onCancel={() => onClose()} footer={<Button onClick={() => onClose()}>Sluiten</Button>} title="Product bekijken" open width="45%">
      {undefined === product ? (
        <Skeleton />
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "row", columnGap: 24, marginBottom: 16 }}>
            {undefined !== product && product.productType.images.length > 0 ? (
              <div style={{ marginBlock: "auto", width: 250 }}>
                <Carousel autoplay>
                  {product.productType.images.map((image, index) => (
                    <img key={index} src={image} alt={`${product.productType.description} #${index + 1}`} />
                  ))}
                </Carousel>
              </div>
            ) : (
              <div
                style={{
                  alignItems: "center",
                  borderColor: "var(--fbc-gray-20)",
                  borderRadius: 12,
                  borderStyle: "solid",
                  borderWidth: 2,
                  display: "flex",
                  justifyContent: "center",
                  width: 250,
                }}
              >
                Geen afbeelding beschikbaar
              </div>
            )}
            <Space direction="vertical" size={16} style={{ flex: 1 }}>
              <Typography.Title level={3} style={{ marginBottom: 0 }}>
                {product.productType.description}
              </Typography.Title>
              <Descriptions bordered column={1} layout="horizontal" size="small">
                <Descriptions.Item label="Soort product">
                  {product.productType.category.parent?.name || product.productType.category.name}
                </Descriptions.Item>
                {product.brand && <Descriptions.Item label="Merk/type">{product.brand}</Descriptions.Item>}
                <Descriptions.Item label="Artikelnummer">{product.productType.code ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Ophanglocatie">{product.locationDescription ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Serienummer">{product.serialCode ?? "-"}</Descriptions.Item>
                {product.productionBatch && <Descriptions.Item label="Productiebatch">{product.productionBatch}</Descriptions.Item>}
                <Descriptions.Item label="Laatst gecontroleerd">{formatDDMMYYYY(product.lastCheckedOn)}</Descriptions.Item>
                {featureDisplayReplacementDate(product) && (
                  <Descriptions.Item label="Vervangdatum">{formatDDMMYYYY(product.replacementDate)}</Descriptions.Item>
                )}
                <Descriptions.Item label="Status">{ProductCondition[product.condition as keyof typeof ProductCondition]}</Descriptions.Item>
              </Descriptions>
            </Space>
          </div>
          {product.parts.length > 0 && (
            <Table
              bordered
              columns={partsTableColumns}
              dataSource={product.parts}
              rowKey="id"
              pagination={false}
              title={() => "Onderdelen"}
              size="small"
            />
          )}
        </>
      )}
    </Modal>
  );
}

const ServicedProductQuery = gql`
  query ($productId: ID!) {
    product(id: $productId) {
      id
      productType {
        id
        code
        description
        images
        category {
          id
          name
          parent {
            id
            name
          }
        }
      }
      location {
        id
        name
        address {
          street
          city
          postalCode
          country
        }
      }
      parts {
        id
        productType {
          id
          code
          description
        }
        lastCheckedOn
        productionBatch
        replacementDate
        condition
      }
      brand
      serialCode
      productionBatch
      locationDescription
      installedOn
      lastCheckedOn
      replacementDate
      condition
    }
  }
`;

function formatDDMMYYYY(input: string) {
  return dayjs(input).format("DD/MM/YYYY");
}

const partsTableColumns: ColumnsType<any> = [
  { title: "Naam", key: "productType", render: product => formatProductDescription(product) },
  { title: "Productiebatch", dataIndex: "productionBatch", render: productionBatch => productionBatch ?? "-" },
  { title: "Laatst gecontroleerd", dataIndex: "lastCheckedOn", render: lastCheckedOn => formatDDMMYYYY(lastCheckedOn) },
  { title: "Verloopdatum", dataIndex: "replacementDate", render: replacementDate => formatDDMMYYYY(replacementDate) },
  { title: "Status", dataIndex: "condition", render: condition => ProductCondition[condition as keyof typeof ProductCondition] },
];

function featureDisplayReplacementDate(product: any) {
  const productCategoryName = product.productType.category.parent?.name || product.productType.category.name;

  return productCategoryName !== "Blusmiddel" && !!product.replacementDate;
}
