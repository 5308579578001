import * as Sentry from "@sentry/react";
import { Flex, Spin } from "antd";
import { useEffect, useState } from "react";
import * as React from "react";

import { BACKEND_URL } from "@/constants";
import { useClientContext } from "@/urql/ClientProvider";

import { AuthenticateStatus, AuthenticationContext, InitialValues } from "./context";
import { authenticate, logout, validate2FaCode } from "./functions";
import { loadWhoami, setMimickingUser } from "./token-storage";

export function AuthenticationContextProvider({ children }: { children: React.ReactNode }) {
  const clientContext = useClientContext();

  const [isBooting, setIsBooting] = useState(true);
  const [state, setState] = useState<InitialValues>({
    isFetching: false,
    authenticateStatus: AuthenticateStatus.NOT_AUTHENTICATED,
    user: undefined,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const _switch_user = searchParams.get("_switch_user");

    (async function main() {
      const response = await loadWhoami();

      // no active session known
      if (response === undefined) {
        setIsBooting(false);

        return;
      }

      // user is logged in as employee
      // either the user is trying to mimick a customer (identified from the query params)
      // or else they're incorrectly logged in as employee and need to log out first
      if (response.user.groups.includes("GROUP_EMPLOYEES")) {
        // impersonate user
        if (null !== _switch_user && state.authenticateStatus === AuthenticateStatus.NOT_AUTHENTICATED) {
          const switch_user = decodeURIComponent(_switch_user);

          setMimickingUser(switch_user);
          handleOnLoginImpersonating(switch_user);
          setIsBooting(false);
        } else {
          const errorMessage = `Je hebt een actieve sessie als medewerker en kan niet inloggen in het klantportaal. Log eerst uit bij het medewerkersportaal om te kunnen inloggen als klant.`;
          alert(errorMessage);
        }
      } else {
        // user is logged in.
        setState({ isFetching: false, authenticateStatus: AuthenticateStatus.AUTHENTICATED, user: response.user.email });
        setIsBooting(false);
      }
    })();
  }, []);

  useEffect(() => {
    Sentry.setUser({ email: state.user });
  }, [state.user]);

  const handleOnResetPassword = async (token: string, plainPassword: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(BACKEND_URL + "/users/reset-password", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_confirmationToken=${encodeURIComponent(token)}&_password=${encodeURIComponent(plainPassword)}`,
      });

      if (!response.ok) throw new Error("Could not reset password");
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnCheckPasswordResetToken = async (confirmationToken: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(BACKEND_URL + "/users/confirmation-token?v=" + confirmationToken);
      if (!response.ok) throw new Error("Could not find user for token");

      const content = (await response.json()) as { user: { email: string } };
      if (!content.user?.email) throw new Error("Could not find user for token");

      return content.user.email;
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnRequestPasswordReset = async (emailAddress: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      const response = await fetch(BACKEND_URL + "/users/request-password-reset", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `_username=${encodeURIComponent(emailAddress)}`,
      });

      if (!response.ok) throw new Error("Could not request new password");
    } finally {
      setState(state => ({
        ...state,
        isFetching: false,
      }));
    }
  };

  const handleOnLogin = async (emailAddress: string, password: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
      isAuthenticated: false,
      user: emailAddress,
    }));

    try {
      const response = await authenticate(emailAddress, password);

      const authenticateStatus =
        response.two_factor === "skipped"
          ? AuthenticateStatus.AUTHENTICATED // ..
          : AuthenticateStatus.PENDING_2FA;

      setState({
        authenticateStatus,
        isFetching: false,
        user: emailAddress,
      });

      return authenticateStatus === AuthenticateStatus.AUTHENTICATED ? "completed" : response.two_factor;
    } catch (error) {
      setState(state => ({
        ...state,
        isFetching: false,
        user: undefined,
      }));

      throw error;
    }
  };

  const handleOnValidate2Fa = async (value: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
    }));

    try {
      await validate2FaCode(value);

      setState(state => ({
        ...state,
        isFetching: false,
        authenticateStatus: AuthenticateStatus.AUTHENTICATED,
      }));
    } catch (error) {
      setState(state => ({
        ...state,
        isFetching: false,
      }));

      throw error;
    }
  };

  const handleOnLoginImpersonating = async (emailAddress: string) => {
    setState({
      isFetching: false,
      authenticateStatus: AuthenticateStatus.AUTHENTICATED,
      user: emailAddress,
    });
  };

  const handleOnLogout = async () => {
    await logout();
    clientContext.resetClient();

    setState(state => ({
      ...state,
      authenticateStatus: AuthenticateStatus.NOT_AUTHENTICATED,
      isFetching: false,
      user: undefined,
    }));
  };

  const authContextValue = React.useMemo(
    () => ({
      ...state,
      loginUser: handleOnLogin,
      validate2Fa: handleOnValidate2Fa,
      loginImpersonatingUser: handleOnLoginImpersonating,
      logoutUser: handleOnLogout,
      requestPasswordReset: handleOnRequestPasswordReset,
      checkPasswordResetToken: handleOnCheckPasswordResetToken,
      resetPassword: handleOnResetPassword,
    }),
    [state]
  );

  return (
    <AuthenticationContext.Provider value={authContextValue}>
      {isBooting ? (
        <Flex align="center" justify="center" style={{ height: "100vh", width: "100vw" }}>
          <Spin />
        </Flex>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}
