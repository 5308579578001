import { BACKEND_URL } from "@/constants";

export default async function downloadAttachment(reportUri: string) {
  const response = await fetch(BACKEND_URL + "/" + reportUri, {
    method: "GET",
    credentials: "include",
  });

  const contentDisposition = response.headers.get("Content-Disposition");
  const fileName = contentDisposition !== null ? contentDisposition.substring(contentDisposition.indexOf("filename=") + 9) : "attachment";
  const content = await response.blob();
  const link = document.createElement("a");

  link.href = URL.createObjectURL(content);
  link.download = fileName;

  link.click();
  setTimeout(() => URL.revokeObjectURL(link.href));
}
