import { App, Button, Card, Form, Input, Typography } from "antd";
import { gql, useMutation } from "urql";

import { useAuthentication } from "@/authentication";

export default function UserSettings() {
  const { notification } = App.useApp();
  const { logoutUser, user } = useAuthentication();

  const [{ fetching: isUpdatingSettings }, updateSettingsAsync] = useMutation(UpdatePasswordMutation);
  const [{ fetching: isResetting2Fa }, reset2FactorAsync] = useMutation(ResetTwoFactorMutation);

  const handleOnSubmit = async (values: Record<string, string>) => {
    try {
      await updateSettingsAsync({ plainPassword: values.password });
      notification.success({ message: "Uw wachtwoord is aangepast" });
    } catch {
      notification.error({ message: "Er ging iets mis. Probeer het later nog eens" });
    }
  };

  const handleReset2Factor = async () => {
    try {
      await reset2FactorAsync();
      await logoutUser();
    } catch {
      notification.error({ message: "Er ging iets mis. Probeer het later nog eens" });
    }
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", columnGap: 16, rowGap: 16 }}>
      <Card title="Wachtwoord">
        <Form disabled={isUpdatingSettings} layout="vertical" onFinish={handleOnSubmit}>
          <Form.Item label="E-mail adres" extra="Het is niet mogelijk uw e-mail adres te wijzigen">
            <Input disabled readOnly value={user} />
          </Form.Item>
          <Form.Item label="Nieuw wachtwoord" name="password" required hasFeedback rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Bevestig nieuw wachtwoord"
            name="confirmPassword"
            required
            hasFeedback
            dependencies={["password"]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) return Promise.resolve();
                  return Promise.reject(new Error("Wachtwoorden komen niet overeen"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button loading={isUpdatingSettings} htmlType="submit">
            Wachtwoord wijzigen
          </Button>
        </Form>
      </Card>

      <Card title="Tweefactor authenticatie">
        <Typography.Text>
          Wilt u uw tweefactor authenticatie opnieuw instellen? Klik dan op onderstaande knop. U wordt uitgelogd en kan opnieuw een
          tweefactor applicatie koppelen aan uw account.
        </Typography.Text>

        <Button loading={isResetting2Fa} onClick={handleReset2Factor} style={{ marginTop: 16 }}>
          Tweefactor authenticatie opnieuw instellen
        </Button>
      </Card>
    </div>
  );
}

const UpdatePasswordMutation = gql`
  mutation updatePassword($plainPassword: String!) {
    changeSettings(input: { plainPassword: $plainPassword }) {
      status
    }
  }
`;

const ResetTwoFactorMutation = gql`
  mutation resetTwoFactor() {
    resetTwoFactor {
      message
    }
  }
`;
