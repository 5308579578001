import { CalendarOutlined, HomeOutlined, MedicineBoxOutlined, SettingOutlined, WarningOutlined } from "@ant-design/icons";

import Dashboard from "./Dashboard";
import IssueTracker from "./IssueTracker";
import Appointments from "./PastAndFutureAppointments";
import Products from "./Products";
import UserSettings from "./UserSettings";

interface RouteDefinition {
  element: JSX.Element;
  icon: JSX.Element;
  path: string;
  title: JSX.Element | string;
}

export const routes: RouteDefinition[] = [
  { element: <Dashboard />, icon: <HomeOutlined />, path: "/", title: "Startpagina" },
  { element: <Appointments />, icon: <CalendarOutlined />, path: "appointments", title: "Onderhoudsbeurten" },
  { element: <Products />, icon: <MedicineBoxOutlined />, path: "products", title: "Veiligheidsmiddelen" },
  { element: <IssueTracker />, icon: <WarningOutlined />, path: "issues", title: "Berichten" },
  { element: <UserSettings />, icon: <SettingOutlined />, path: "settings", title: "Accountinstellingen" },
];
