import { Alert } from "antd";
import * as React from "react";

import { isMimickingUser } from "@/authentication/token-storage";

export default function MaybeMimickingUserBanner() {
  const isMimickingUserEmailAddress = isMimickingUser();
  if (!isMimickingUserEmailAddress) return null;

  return <Alert banner message={`Je ziet het klantenportaal door de ogen van ${isMimickingUserEmailAddress}`} type="info" />;
}
