import "./layout.css";

import { Card } from "antd";
import { Outlet } from "react-router";

export function Layout() {
  return (
    <div className="login-background">
      <Card bordered={false} style={{ width: "33.3%" }}>
        <Outlet />
      </Card>
    </div>
  );
}
