import { Table } from "antd";
import * as React from "react";

import formatProductDescription from "@/functions/format-product-description";

function formatProductProps(product: Record<string, any>) {
  return (
    <span>
      <span>{formatProductDescription(product)}</span>
      {product.serialCode && <div style={{ fontStyle: "italic" }}>Serienummer: {product.serialCode}</div>}
      {product.productionBatch && <div style={{ fontStyle: "italic" }}>Batchnummer: {product.productionBatch}</div>}
    </span>
  );
}

interface RelatedProduct {
  id: string;
  productType: {
    id: string;
    description: string;
  };
  name: string;
  brand: string;
  locationDescription: string;
  serialCode: string;
  replacementDate: string;
}

interface RelatedProducstTableProps {
  products: RelatedProduct[];
}

export default function RelatedProducstTable({ products }: RelatedProducstTableProps) {
  return (
    <Table
      bordered
      columns={[
        {
          title: "Product",
          render: product => formatProductProps(product),
        },
        {
          title: "Ophanglocatie",
          dataIndex: "locationDescription",
        },
      ]}
      title={() => "Gerelateerde producten"}
      dataSource={products}
      rowKey={product => product.id}
      pagination={false}
      size="small"
    />
  );
}
